// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academy-js": () => import("./../../../src/pages/academy.js" /* webpackChunkName: "component---src-pages-academy-js" */),
  "component---src-pages-approach-tsx": () => import("./../../../src/pages/approach.tsx" /* webpackChunkName: "component---src-pages-approach-tsx" */),
  "component---src-pages-blog-strapi-post-slug-tsx": () => import("./../../../src/pages/blog/{StrapiPost.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-strapi-post-slug-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-consulting-js": () => import("./../../../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-development-js": () => import("./../../../src/pages/development.js" /* webpackChunkName: "component---src-pages-development-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-strapi-services-slug-tsx": () => import("./../../../src/pages/services/{StrapiServices.slug}.tsx" /* webpackChunkName: "component---src-pages-services-strapi-services-slug-tsx" */),
  "component---src-pages-sharepoint-apps-js": () => import("./../../../src/pages/sharepoint-apps.js" /* webpackChunkName: "component---src-pages-sharepoint-apps-js" */),
  "component---src-pages-team-strapi-author-slug-tsx": () => import("./../../../src/pages/team/{StrapiAuthor.slug}.tsx" /* webpackChunkName: "component---src-pages-team-strapi-author-slug-tsx" */)
}

